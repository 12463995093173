<template>
  <div>
    <c-search-box v-if="popupParam.isSpecial" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-radio
            :editable="editable"
            :comboItems="comboItemsDept"
            label="대상부서"
            name="isContentFlag"
            @datachange="datachange"
            v-model="searchParam.isContentFlag">
          </c-radio>
        </div>
      </template>
    </c-search-box>
    <c-card title="부서 상세" class="cardClassDetailForm no-margin">
      <template slot="card-button">
        <q-btn-group outline >
          <q-chip 
            v-if="popupParam.isSpecial"
            outline square clickable 
            size="md"
            color="light-green-8" 
            text-color="white"
            icon="contact_support"
            label="범례"
            style="height:26px;margin-right:2px !important;"
            class="q-ml-sm">
            <q-popup-proxy>
              <q-banner>
                <b>
                ※ 유해인자 관련
                </b>
                <br><br>
                <q-chip color="blue-7" />
                  화학적인자
                  <br>
                <q-chip color="deep-purple-6" />
                  물리적인자
                  <br>
                <q-chip color="green-7" />
                  분진
                  <br>
                <q-chip color="black" />
                  야간작업
              </q-banner>
            </q-popup-proxy>
          </q-chip>
          <c-btn 
            v-if="editable" 
            label="LBLSELECT" 
            icon="check"
            @btnClicked="select" />
          <!-- <c-btn 
            v-if="editable" 
            label="LBLSEARCH" 
            icon="search"
            @btnClicked="getList" /> -->
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12">
          <q-tree
            ref="dept-multi"
            :nodes="deptTree"
            tick-strategy="strict"
            node-key="deptCd"
            label-key="deptName"
            children-key="children"
            no-nodes-label="부서 정보가 없습니다."
            :ticked.sync="checkDepts"
            :default-expand-all="true"
          >
            <!-- <template v-slot:default-header="prop">
              <div class="row items-center">
                <q-icon :name="prop.node.icon || 'share'" color="orange" size="28px" class="q-mr-sm" />
                <div class="text-weight-bold text-primary">{{ prop.node.deptName }}</div>
              </div>
            </template> -->
            <template v-if="popupParam.isSpecial" v-slot:default-body="prop">
              <span v-if="prop.node.hazardNames1" class="text-blue-7">
                {{ prop.node.hazardNames1 }} 
              </span>
              <span v-if="prop.node.hazardNames2" class="text-deep-purple-6">
                {{ prop.node.hazardNames2 }} 
              </span>
              <span v-if="prop.node.hazardNames3" class="text-green-7">
                {{ prop.node.hazardNames3 }} 
              </span>
              <span v-if="prop.node.hazardNames4" class="text-black">
                {{ prop.node.hazardNames4 }} 
              </span>
            </template>
          </q-tree>
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
import arrayToTree from 'array-to-tree';
export default {
  name: 'dept-multi-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
          depts: [],
          oneMoreDepts: [],
          isSpecial: false,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
  },
  data() {
    return {
      checkDepts: [],
      editable: true,
      searchParam: {
        isContentFlag: 'N',
      },
      tempDepts1: [],
      tempDepts2: [],
      comboItemsDept: [
        { code: 'N', codeName: '전체 부서', },
        { code: 'Y', codeName: '특수건강검진 대상부서만', },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    deptTree() {
      let val = this.convertTree(this.popupParam.depts);
      return val ? val : [];
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.tempDepts1 = this.$_.cloneDeep(this.popupParam.depts);
      this.tempDepts2 = this.$_.cloneDeep(this.popupParam.oneMoreDepts);

      this.getList();
    },
    getList() {
      if (this.searchParam.isContentFlag === 'Y') {
        this.popupParam.depts = this.tempDepts2;
      } else {
        this.popupParam.depts = this.tempDepts1;
      }
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upDeptCd',
        customID: 'deptCd',
      });
      return tree;
    },
    select() {
      if (!this.checkDepts || this.checkDepts.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '부서를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', this.$refs['dept-multi'].getTickedNodes());
      }
    },
    datachange() {
      this.getList();
    }
  }
};
</script>
